<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="block-container py-4">
      <div class="sub-title underlined pb-2 mb-2">
        <h1>売上実績</h1>
      </div>
      <div class="block" v-for="(transaction, index) in transactions" :key="index">
        <div class="date">{{transaction.processedMonth}}</div>
        <div class="amount">
          <label>合計</label>
          <span>¥{{transaction.totalAmount | formatCurrency}}</span>
        </div>
        <div class="amount small">
          <label>OPEN</label>
          <span>¥{{transaction.totalOpenAmount | formatCurrency}}</span>
        </div>
        <div class="amount small">
          <label>既存</label>
          <span>¥{{transaction.totalKizonAmount | formatCurrency}}</span>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import Analytics from '@/model/Analytics.js';
export default {
  data(){
    return {
      loading: false,
      transactions: []
    }
  },
  mounted(){
    this.loading = true;
    Analytics.getTotalTransactions(this.$store.state.user.companyCode).then(result => {
      this.transactions = result.data.transactions;
      this.loading = false;
    }).catch(reason => this.$errorMessage(undefined, {reason}));
  }
}
</script>

<style lang="scss" scoped>
  .block-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    .block{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      &:nth-child(even){
        background:rgba(0, 0, 0, 0.05);
      }
      .amount{
        box-sizing:border-box;
        min-width: 116px;
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        label{
          display:block;
          text-align:left;
          color: #737575;
        }
        span{
          display:block;
          text-align: right;
          border-bottom: 1px solid #868888;
        }
        margin: 0rem .25rem;
        &.small{
          font-size: 0.8rem;
          color: #737575;
        }
      }
      .date{
        display:flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        text-align:center;
        color: #868888;
      }
    }
  }
</style>