<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="mt-4 px-2 py-4">
      <div class="sub-title underlined pb-2">
        <h1>利用者評価</h1>
      </div>
      <div class="chart-container">
        <RadarChart :chart-data="chartData" :options="{maintainAspectRatio: false}"/>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import RadarChart from '@/components/chart/RadarChart.vue'
import Analytics from '@/model/Analytics.js';

export default {
  components: { RadarChart },
  data: function(){
    return {
      chartData: null,
      loading: false
    }
  },
  mounted () {
    this.fillData()
  },
  methods: {
      fillData () {
        this.loading = true;
        Analytics.getScoreComparison(this.$store.state.user.companyCode, {}).then(result => {
          this.chartData = {
            labels: ["価格競争力", "勝率", "不良品率", "納期遵守率", "見積回答率  "],
            datasets: [
              {
                label: "全体",
                backgroundColor: "rgba(206, 212, 218, .45)",
                data: [
                  result.data.average.priceCompetitivenessScore, 
                  result.data.average.orderReceiveRate, 
                  result.data.average.defectiveDeliveryRate, 
                  result.data.average.deliveryComplianceRate,
                  result.data.average.estimateResponseRate]
              },
              {
                label: "貴社",
                backgroundColor: "rgba(0, 123, 255, .45)",
                data: [
                  result.data.self.priceCompetitivenessScore, 
                  result.data.self.orderReceiveRate, 
                  result.data.self.defectiveDeliveryRate, 
                  result.data.self.deliveryComplianceRate,
                  result.data.self.estimateResponseRate]
              },
            ]
          }
          this.loading = false;
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      }
  }

}
</script>

<style lang="scss" scoped>
  .chart-container > div{
    height: 300px;
    position: relative;
  }
</style>
