import Model from './_base';

export default class CompanyInfo extends Model {
    static entity = 'company';
    
    constructor() {
        super(CompanyInfo.entity);
    }

    static getTodos(companyCode, token, supplierCode, userCode){
        return Model.axios.get(this.url('/' + companyCode + '/todo-count' + '?token=' + token + '&supplierCode=' + supplierCode + '&userCode=' + userCode));
    }

    static getCompanyStatus(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/check-status'));
    }

    static getCompanyRelations(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/buyers'));
    }

    static getActiveBuyers(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/buyers/active'));
    }

    static getCompanyCategory(companyCode, hasProductNameCount = false, loadRoot = true){
        return Model.axios.get(
            this.url('/' + companyCode + '/category'), {
                params: {
                    hasProductNameCount: hasProductNameCount,
                    loadRoot: loadRoot,
                }
            });
    }

    static getCompanyArea(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/area'));
    }

    static getCompanyProperties(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/property'));
    }

    static getCompanyEstates(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/estates'));
    }

    static getCompanyBankAccount(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/bank-account'));
    }

    static getCompanyVerifyAddress(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/verify-address'));
    }

    static getCompanyPatents(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/patents'));
    }

    static getCompanyLicenses(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/licenses'));
    }

    static getCompanyContracts(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/contracts'), {noLoading: true});
    }

    static getCompanyBasicInfo(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/basic'));
    }

    static getSummary(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/summary'));
    }

    static getTsrStatus(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/tsr-status'));
    }

    static getBuyerUseFunction(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/buyer-use-function'))
    }

    static getCorporateNumberSearchResult(companyCode, searchType, searchString){
        return Model.axios.get(this.url('/' + companyCode + '/' + searchType + '/' + searchString + '/corporate-number-search'));
    }

    static saveCompanyBasicInfo(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/basic'), data);
    }

    static saveCompanyAddon(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/addon'), data);
    }

    static saveCompanyCategory(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/category'), data);
    }

    static saveCompanyArea(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/area'), data);
    }

    static saveCompanyEstates(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/estates'), data);
    }

    static saveCompanyBankAccount(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/bank-account'), data);
    }

    static saveCompanyVerifyAddress(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/verify-address'), data);
    }

    static saveCompanyPatents(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/patents'), data);
    }

    static saveCompanyLicenses(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/licenses'), data);
    }

    static saveCompanyBuyer(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/buyers'), data);
    }

    static signCompanyContract(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/contracts/' + data.contractCode), data);
    }

    static changeStatus(companyCode, data){
        return Model.axios.post(this.url('/' + companyCode + '/change-status'), data);
    }

    static toOpenSupplier(companyCode){
        return Model.axios.post(this.url('/' + companyCode + '/to-open-supplier'));
    }

    static initPropertyInput(data, dataToAdd = [{}]){
        if(data){
            if(data.length <= 0) return dataToAdd;
            else return data;
        }else return dataToAdd;
    }

    static cleanPropertyData(data){
        if(data){
            return data.filter(r => !(Object.keys(r).length === 0 && r.constructor === Object));
        }
    }

    static cleanYakuinData(data){
        if(data){
            return data.filter(r => r.name && r.nameKana && r.birthYear && r.birthMonth && r.birthDay);
        }
    }

    static cleanFinancialInfo(data){
        if(data){
            return data.filter(r => r.year && r.month && r.sales && r.ordinaryProfit && r.profitAfterTax);
        }
    }

    static noticeList(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/get-supplier-notice-list'));
    }
    static noticeDetail(companyCode, noticeCode){
        return Model.axios.get(this.url('/' + companyCode + '/get-supplier-notice-detail?noticeCode=' + noticeCode));
    }
    static getDownloadFileLink(companyCode, filePath, fileName){
        filePath = encodeURI(filePath);
        fileName = encodeURI(fileName);
        return Model.axios.get(this.url('/' + companyCode + '/download-link-amazon-s3?filePath=' + filePath + '&fileName=' + fileName));
    }
    // BUG2023-2043
    static getFooterSettingList(companyCode) {
        return Model.axios.get(this.url('/' + companyCode + '/get-footer-setting-list'));
    }
    // BUG2023-2043
    static getFooterSettingById(companyCode, id) {
        return Model.axios.get(this.url('/' + companyCode + '/get-footer-setting-by-id?id=' + id));
    }
}