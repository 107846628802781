<template>
  <div class=" px-2 py-4" style="min-height: 100%;">
    <div class="sub-title underlined pb-2">
      <h1>売れ筋ランキング</h1>
    </div>
    <div class="mt-2 mb-2 row">
      <div class="col col-auto">
        <b-button-group size="sm">
          <j-button class="no-min" v-on:click="onMonthSelect(0)" :variant="params.selectedMonth === 0?'primary':'light'">
            今月
          </j-button>
          <j-button class="no-min" v-on:click="onMonthSelect(1)" :variant="params.selectedMonth === 1?'primary':'light'">
            前月
          </j-button>
        </b-button-group>
      </div>
      <div class="col col-auto text-right">
        <BuyerSelect v-on:change="onBuyerChange"/>
      </div>
      <div class="col-lg text-lg-right col-md-12">
        <CategorySelect v-on:change="onCategoryChange"/>
      </div>
    </div>
    <div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="clickable text-center" v-on:click="onSort('productName')">
              <font-awesome-icon class="mr-2" :icon="pageable.sortDirection === 'desc' ? 'angle-down':'angle-up'" v-if="pageable.sortBy === 'productName'"/>
              商品・サービス
            </th>
            <th class="clickable text-center" v-on:click="onSort('unitPrice')">
              <font-awesome-icon class="mr-2" :icon="pageable.sortDirection === 'desc' ? 'angle-down':'angle-up'" v-if="pageable.sortBy === 'unitPrice'"/>
              単価
            </th>
            <th class="clickable text-center" v-on:click="onSort('totalQuantity')">
              <font-awesome-icon class="mr-2" :icon="pageable.sortDirection === 'desc' ? 'angle-down':'angle-up'" v-if="pageable.sortBy === 'totalQuantity'"/>
              数量
            </th>
            <th class="clickable text-center" v-on:click="onSort('totalTransactionQuantity')">
              <font-awesome-icon class="mr-2" :icon="pageable.sortDirection === 'desc' ? 'angle-down':'angle-up'" v-if="pageable.sortBy === 'totalTransactionQuantity'"/>
              件数
            </th>
            <th class="clickable text-center" v-on:click="onSort('totalAmount')">
              <font-awesome-icon class="mr-2" :icon="pageable.sortDirection === 'desc' ? 'angle-down':'angle-up'" v-if="pageable.sortBy === 'totalAmount'"/>
              売上
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rank, index) in rankings" v-bind:key="index">
            <td>{{rank.productName}}</td>
            <td class="text-right">￥{{rank.unitPrice | formatCapitalCurrencyView}}</td>
            <td class="text-right">{{rank.totalQuantity | formatCurrency}}</td>
            <td class="text-right">{{rank.totalTransactionQuantity | formatCurrency}}</td>
            <td class="text-right">￥{{rank.totalAmount | formatCapitalCurrencyView}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Analytics from '@/model/Analytics.js';

import CategorySelect from '@/components/category/CategorySelect.vue';
import BuyerSelect from '@/components/buyer/BuyerSelect.vue';

export default {
  components: { CategorySelect, BuyerSelect },
  data: function(){
    return {
      params:{
        selectedCategory: {},
        selectedBuyerCode: null,
        selectedMonth: 0,
        processedOn: moment().format('YYYY-MM-DD') //TODO: Doing this from the UI feels wrong care!
      },
      rankings: [],
      pageable: {
        pageNumber: 0,
        pageSize: 20,
        sortBy: 'totalAmount',
        sortDirection: 'desc'
      }
    }
  },
  mounted: function(){
    this.loadRankings();
  },
  methods:{
    loadRankings(){
      Analytics.getProductRanking(this.$store.state.user.companyCode, {
        ...this.params.selectedCategory,
        buyerCompanyCode: this.params.selectedBuyerCode,
        processedOn: this.params.processedOn,
        pageNumber: this.pageable.pageNumber,
        pageSize: this.pageable.pageSize,
        sort: this.pageable.sortBy + ',' + this.pageable.sortDirection
      }).then(result => {
        this.rankings = result.data.content;
        //for(let i = this.rankings.length; i < 20; i++) this.rankings.push({});
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
    onCategoryChange(selectedCategory){
      this.params.selectedCategory = selectedCategory;
      this.loadRankings();
    },
    onSort(col){
      this.$set(this.pageable, 'sortBy', col);
      this.$set(this.pageable, 'sortDirection', this.pageable.sortDirection === 'desc' ? 'asc' : 'desc');
      this.loadRankings();
    },
    onMonthSelect(value){
      this.$set(this.params, 'selectedMonth', value);
      this.params.processedOn = moment().subtract(this.params.selectedMonth, 'month').format('YYYY-MM-DD');
      this.loadRankings();
    },
    onBuyerChange(selectedBuyer){
      if(selectedBuyer){
        this.params.selectedBuyerCode = selectedBuyer.buyerCode;
      }else {
        this.params.selectedBuyerCode = null;
      } 
      this.loadRankings();
    }
  }
}
</script>

<style lang="scss" scoped>
  .clickable{
    cursor: pointer;
  }
</style>
