import Model from './_base';


export default class Category extends Model {
    static entity = 'category';
    
    constructor() {
        super(Category.entity);        
    }

    
    static getRootCategory(keyword){
        return Model.axios.get(this.url(keyword?('?keyword='+keyword):''));
    }

    static getChildCategory(id){
        return Model.axios.get(this.url('/fetch?parent=' + id));
    }

    static getProductNames(categoryId){
        return Model.axios.get(this.url('/' + categoryId + '/product-name'));
    }

    static addProductName(categoryId, companyCode, data){
        return Model.axios.post(this.url('/' + categoryId + '/product-name?companyCode=' + companyCode), data);
    }

    static downloadCategory(companyCode){
        return Model.axios.get(this.url('/' + companyCode + '/download'), { responseType: 'blob' });
    }

    static uploadCategory(companyCode, data){
        const formData = new FormData()
        formData.append('file', data)
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return Model.axios.post(this.url('/' + companyCode + '/upload'), formData, config);
    }

    static toCompanyCategory(nodes, isRemoved = false){
        
        var output = [];
        nodes.forEach(node => {
            let n = {
                categoryId: node.id,
                isBranch: (node.childCount > 0 && (!node.children || (node.children && node.children.length > 0))),
                isRemoved: isRemoved || (!node.updated && node.registered),
                isUpdated: !node.registered && node.updated,
                isPristine: node.updated && node.registered
            };
            if(n.isUpdated || n.isRemoved || n.isPristine){
                output.push(n);
                if(n.isBranch && node.children && node.children.length > 0) {
                    output = output.concat(this.toCompanyCategory(node.children, n.isRemoved)); 
                }
            }
        });
        return output;
    }

    // count selected categories
    static countCompanyCategory(nodes){
        let c = 0;
        for(let i = 0; i < nodes.length; i++){
            if(!nodes[i].isBranch && (nodes[i].isPristine || (nodes[i].isUpdated && !nodes[i].isRemoved))){
                c+=1;
            }
        }
        return c;
    }

    static mergeCategory(target, source){
        source.children.forEach(smid => {
            let tmid = target.children.find(tmid => tmid.id === smid.id);
            if(tmid){
                let usmalls = smid.children.filter(ssmall => !tmid.children.find(tsmall => tsmall.id === ssmall.id));
                tmid.children = tmid.children.concat(usmalls);
                tmid.children.sort(function(a, b) {
                    if (a.categoryCode < b.categoryCode) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
            }else{
                    target.children.push(smid);
                }
        })
        return target;
    }
}