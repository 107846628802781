<template>
  <div>
    <ul class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">システム管理</router-link></li>  
      <li><a>アナリティクス</a></li>
    </ul>
    <div class="page">
      <div class="page-title">
        <h1>アナリティクス</h1>
      </div>
      <div class="pl-1 pr-1 pt-0 pb-5">
        <div class="row mt-4">
          <div class="col-6">
            <Ranking/>
          </div>
          <div class="col-6">
            <TransactionTotals/>
            <SalesComparison/>
            <Score/>
          </div>
        </div>
      </div>      
    </div>
  </div>
</template>

<style scoped lang="scss">
  
</style>
<script>
 
  import SalesComparison from '@/components/dashboard/SalesComparison.vue'
  import Ranking from '@/components/dashboard/Ranking.vue'
  import Score from '@/components/dashboard/Score.vue'
  import TransactionTotals from '@/components/dashboard/TransactionTotals.vue';

  export default {
    components: { SalesComparison, Ranking, Score, TransactionTotals },
    data: () => ({
      status: {}
    }),
    created: function(){
      
    }
  }
</script>