<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="px-2 py-4">
      <div class="sub-title underlined pb-2">
        <h1>売上比率</h1>
      </div>
      <div class="mt-2 mb-2 row">
        <div class="col col-auto">
          <b-button-group size="sm">
            <j-button class="no-min" v-on:click="onDisplayTypeSelect('amount')" :variant="params.selectedType === 'amount' ? 'primary':'light'">金額</j-button>
            <j-button class="no-min" v-on:click="onDisplayTypeSelect('count')" :variant="params.selectedType === 'count' ? 'primary':'light'">件数</j-button>
          </b-button-group>
        </div>
        <div class="col col-auto">
          <BuyerSelect v-on:change="onBuyerChange"/>
        </div>
        <div class="col text-right">
          <CategorySelect v-on:change="onCategoryChange"/>
        </div>
      </div>
      <div class="chart-container">
        <BarChart v-bind:chart-data="chartData" :options="{
          maintainAspectRatio: false, 
          locale:'ja-JP',
          scales: {
            yAxes: [{
              ticks: {
                callback:(value) => {
                  if(params.selectedType==='amount')
                    return new Intl.NumberFormat('ja-JP', {  style: 'currency',  currency: 'JPY', notation: 'compact',currencyDisplay: 'name'}).format(value);
                  else
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }
              }
            }]
            }
        }"/>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import moment from 'moment';
import Analytics from '@/model/Analytics.js';

import BarChart from '@/components/chart/BarChart.vue'


export default {
  components: { BarChart },
  data: function(){
    return {
      loading:false,
      params:{
        selectedCategory: {},
        selectedBuyerCode: null,
        selectedType: 'amount'
      },
      chartData: null
    }
  },
  mounted () {
    this.fillData()
  },
  methods: {
      fillData () {
        this.loading = true;
        Analytics.getSalesComparison(this.$store.state.user.companyCode, {
          ...this.params.selectedCategory,
          buyerCompanyCode: this.params.selectedBuyerCode,
          from: this.getThreeMonthsPrior(),
          sort: 'processedOn,asc'
        }).then(result => {
          var datas = result.data;
          var chartData = {
            labels: datas.items.map(d => d.processedMonth),
            datasets: [
              {
                self: false,
                label: '全体',
                backgroundColor: 'rgba(206, 212, 218, .45)',
                data: []
              },
              {
                self: true,
                label: '貴社',
                backgroundColor: 'rgba(0, 123, 255, .45)',
                data: []
              }
            ]
          };
          for(let i = 0; i < datas.items.length; i++) {
            let dm = datas.items[i];
            chartData.datasets.find(ds => ds.self === true).data.push(
              dm.self[this.params.selectedType]);
            chartData.datasets.find(ds => ds.self === false).data.push(
              dm.average[this.params.selectedType]); 
          }
          this.chartData = chartData;
          this.loading = false;
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onCategoryChange(selectedCategory){
        this.params.selectedCategory = selectedCategory;
        this.fillData();
      },
      onBuyerChange(selectedBuyer){
        if(selectedBuyer){
          this.params.selectedBuyerCode = selectedBuyer.buyerCode;
        }else{
          this.params.selectedBuyerCode = null;
        }
        this.fillData();
      },
      onDisplayTypeSelect(type){
        this.$set(this.params, 'selectedType', type);
        this.fillData();
      },
      getThreeMonthsPrior () {
        return moment().subtract(3, 'month').format('YYYY-MM-DD');
      }
  }

}
</script>

<style lang="scss" scoped>
  .chart-container > div{
    height: 300px;
    position: relative;
  }
</style>
