<template>
  <div>
    <b-select class="buyer-select" size="sm" v-model="selectedBuyer" v-on:change="onChange">
       <template #first>
        <b-form-select-option :value="null">バイヤを選択してください。</b-form-select-option>
      </template>
      <b-form-select-option v-for="(buyer, index) in activeBuyers" v-bind:key="index" :value="buyer">[{{buyer.buyerCompanyGroupCode}}]{{buyer.buyerCompanyGroupName}}</b-form-select-option>
    </b-select>
  </div>
</template>

<script>
import CompanyModel from '@/model/CompanyInfo.js';

export default {
  data: function(){
    return  {
      selectedBuyer: null,
      activeBuyers:[]
    }
  },
  mounted: function(){
    CompanyModel.getActiveBuyers(this.$store.state.user.companyCode).then(result => {
      this.activeBuyers = result.data;
    }).catch(reason => this.$errorMessage(undefined, {reason}));
  },
  methods:{
    onChange(){
      this.$emit('change', this.selectedBuyer);
    }
  }
}
</script>

<style>
  .buyer-select{
    display:inline-block;
  }
</style>