var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('div',{staticClass:"px-2 py-4"},[_c('div',{staticClass:"sub-title underlined pb-2"},[_c('h1',[_vm._v("売上比率")])]),_c('div',{staticClass:"mt-2 mb-2 row"},[_c('div',{staticClass:"col col-auto"},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('j-button',{staticClass:"no-min",attrs:{"variant":_vm.params.selectedType === 'amount' ? 'primary':'light'},on:{"click":function($event){return _vm.onDisplayTypeSelect('amount')}}},[_vm._v("金額")]),_c('j-button',{staticClass:"no-min",attrs:{"variant":_vm.params.selectedType === 'count' ? 'primary':'light'},on:{"click":function($event){return _vm.onDisplayTypeSelect('count')}}},[_vm._v("件数")])],1)],1),_c('div',{staticClass:"col col-auto"},[_c('BuyerSelect',{on:{"change":_vm.onBuyerChange}})],1),_c('div',{staticClass:"col text-right"},[_c('CategorySelect',{on:{"change":_vm.onCategoryChange}})],1)]),_c('div',{staticClass:"chart-container"},[_c('BarChart',{attrs:{"chart-data":_vm.chartData,"options":{
        maintainAspectRatio: false, 
        locale:'ja-JP',
        scales: {
          yAxes: [{
            ticks: {
              callback:function (value) {
                if(_vm.params.selectedType==='amount')
                  { return new Intl.NumberFormat('ja-JP', {  style: 'currency',  currency: 'JPY', notation: 'compact',currencyDisplay: 'name'}).format(value); }
                else
                  { return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); }
              }
            }
          }]
          }
      }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }