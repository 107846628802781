<template>
  <div>
    <b-select size="sm" class="category-select" v-model="selectedTopCategory" v-on:change="onTopSelected">
       <template #first>
        <b-form-select-option :value="null">*大カテゴリ</b-form-select-option>
      </template>
       <b-form-select-option v-for="(category, index) in topCategories" v-bind:key="index" :value="category">{{category.categoryName}}</b-form-select-option>
    </b-select>
    <font-awesome-icon class="ml-2 mr-2" icon="angle-right"/>
    <b-select size="sm" class="category-select" v-model="selectedMidCategory" v-on:change="onMidSelected">
      <template #first>
        <b-form-select-option :value="null">*中カテゴリ</b-form-select-option>
      </template>
      <b-form-select-option v-for="(category, index) in midCategories" v-bind:key="index" :value="category">{{category.categoryName}}</b-form-select-option>
    </b-select>
    <font-awesome-icon class="ml-2 mr-2" icon="angle-right"/>
    <b-select size="sm" class="category-select" v-model="selectedLowCategory" v-on:change="onLowSelected">
      <template #first>
        <b-form-select-option :value="null">*小カテゴリ</b-form-select-option>
      </template>
      <b-form-select-option v-for="(category, index) in lowCategories" v-bind:key="index" :value="category">{{category.categoryName}}</b-form-select-option>
    </b-select>
  </div>
</template>

<script>
import Category from '@/model/Category';
export default {
  data: () => ({
      topCategories: [],
      midCategories: [],
      lowCategories: [],
      selectedTopCategory: null,
      selectedMidCategory: null,
      selectedLowCategory: null
    }),
  mounted: function(){
    Category.getRootCategory().then(result => {
      this.topCategories = result.data;
    }).catch(reason => this.$errorMessage(undefined, {reason}));
  },
  methods:{
    onTopSelected(){
      if(this.selectedTopCategory)
        Category.getChildCategory(this.selectedTopCategory.id).then(result => {
          this.midCategories = result.data.children;
          this.onCategoryEmit();
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      else{
        this.midCategories = [];
        this.lowCategories = [];
        this.onCategoryEmit();
      }
    },
    onMidSelected(){
      if(this.selectedMidCategory)
        this.lowCategories = this.selectedMidCategory.children;
      else 
        this.lowCategories = [];
      this.onCategoryEmit();
    },
    onLowSelected(){
      this.onCategoryEmit();
    },
    onCategoryEmit(){
      this.$emit('change', {
        topCategoryId: this.selectedTopCategory? this.selectedTopCategory.id: null,
        midCategoryId: this.selectedMidCategory? this.selectedMidCategory.id: null,
        lowCategoryId: this.selectedLowCategory? this.selectedLowCategory.id: null
      })
    }
  }
}
</script>

<style>
  .category-select{
    display:inline-block;
    width: 120px;
  }
</style>