import Model from './_base';

export default class CompanyInfo extends Model {
    static entity = 'company';
    
    constructor() {
        super(CompanyInfo.entity);
    }

    static getProductRanking(companyCode, parameters){
        return Model.axios.get(this.url('/' + companyCode + '/product-ranking'), {
            params: parameters,
            noLoading: true
        });
    }


    static getTotalTransactions(companyCode, parameters){
        return Model.axios.get(this.url('/' + companyCode + '/total-transactions'), {
            params: parameters,
            noLoading: true
        });
    }

    static getSalesComparison(companyCode, parameters){
        return Model.axios.get(this.url('/' + companyCode + '/sales-comparison'), {
            params: parameters,
            noLoading: true
        });
    }

    static getScoreComparison(companyCode, parameters){
        return Model.axios.get(this.url('/' + companyCode + '/score-comparison'), {
            params: parameters,
            noLoading: true
        });
    }

}